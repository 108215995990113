import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconLocation, IconCash } from "../iconsDashboard";
import { useQuery } from "../../../hooks";
import api from "../../../services/api";
import { useAuth } from "../../../context/authContext";
import SideBar from "../SideBar";
import { thousandSeparator } from "../../../utils";

const navigation = [
  // {
  //   name: 'Misioneros',
  //   href: '/admin/missionaries',
  //   icon: IconUserGroup,
  //   current: false,
  // },
  {
    name: "Registro de Asistencia",
    href: "/admin/meetings",
    icon: IconLocation,
    current: false,
  },
  { name: "Pagos", href: "/admin/payments", icon: IconCash, current: true },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PaymentRegist() {
  let query = useQuery();
  let auth = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState(null);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    api.payments
      .create({
        type: "check",
        missionary: query.get("ci"),
      })
      .then((res) => {
        setData(res);
        setAmount(res.totalCost - res.totalPaid);
      });
  }, [query]);

  const onSubmit = (e) => {
    e.preventDefault();
    api.payments
      .create({
        type: "pay",
        missionary: query.get("ci"),
        amount,
      })
      .then((res) => {
        navigate("/admin/payments?status=success");
      });
  };

  const isSubmitDisabled = () => {
    if (!data) return true;
    if (data.entry.isInWaitingList) return true;
    if (!data.entry.isVaccinated) return true;
    if (!amount || amount.length < 4) return true;
    return false;
  };

  return (
    <>
      <SideBar />
      <div className="md:pl-64 sm:px-6 md:px-8">
        <div className="md:grid place-items-center sm:rounded-lg body-inscription">
          <div className="px-4 py-5 sm:p-6 ">
            <h1 className="font-extrabold text-gray-700 title-cedula">
              Registro de Pagos
            </h1>
            <div className="py-10 px-5 md:px-10 bg-white card-cedula card-cedula-ph">
              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Nombre y apellido</p>
                  <p>{`${data?.entry?.missionary?.firstName} ${data?.entry?.missionary?.lastName}`}</p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Cédula</p>
                  <p>{data?.entry?.missionary?.issuedId}</p>
                </div>
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-6 sm:col-span-6">
                  <p className="text-gray-500">Fecha y hora de inscripción</p>
                  <p>
                    {data &&
                      new Date(data?.entry.entryDate).toLocaleDateString(
                        "es-py",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                  </p>
                </div>
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Lista de espera</p>
                  {data?.entry?.isInWaitingList ? (
                    <p className="text-red-700">SI</p>
                  ) : (
                    <p className="text-green-700">NO</p>
                  )}
                </div>
                {/*<div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Vacunación COVID-19</p>
                  {data?.entry?.isVaccinated ? (
                    <p className="text-green-700">SI</p>
                  ) : (
                    <p className="text-red-700">NO</p>
                  )}
                  </div>*/}
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Total a pagar</p>
                  {data?.totalCost}
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Monto ya abonado</p>
                  {data?.totalPaid}
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Pendiente de pago</p>
                  {data?.totalCost - data?.totalPaid}
                </div>
              </div>

              <br />
              <form onSubmit={onSubmit}>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Monto a abonar</p>
                  <input
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    type="number"
                    placeholder="230.000"
                    className="mt-2 px-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full block sm:text-sm border-gray-300 rounded-md input-cedula "
                  ></input>
                  <p>
                    {/* {entry?.missionary?.birthDate &&
                      parseDateStr(entry?.missionary?.birthDate)} */}
                  </p>
                </div>

                <br />
                <div>
                  <button
                    onSubmit={onSubmit}
                    type="submit"
                    className="flex-1 w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig mr-10"
                    disabled={isSubmitDisabled()}
                  >
                    Registrar Pago
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentRegist;
